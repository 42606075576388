import Button from 'components/ui/button/Button';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormElementHalf from 'components/ui/formComponents/formElementHalf/FormElementHalf';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import FormText from 'components/ui/formComponents2/formInputs/formText/FormText';
import Icon from 'components/ui/icon/Icon';
import useOnChangeValue from 'customHooks/useOnChangeValue';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useCategories from './hooks/useCategories';
import Checkbox from 'components/ui/formComponents2/checkbox/Checkbox';
import checkFormErrors from 'utils/checkFormErrors';
import { error_color_text } from 'styles/colors';
import { FormData } from './CreateOrEditRole';
import { Category } from './hooks/useCategories';
import Tooltip from 'components/ui/tooltip/Tooltip';
import BlueWrapper from 'components/ui/blueWrapper/BlueWrapper';

const Tag = ({ name }: { name: string }) => {
  return (
    <div
      className='font-12 weight-400 bg-purple text-white rounded-4 px-2 py-1 w-fit-content flex items-center gap-1'
      key={name}>
      <Icon icon='categories' color='white' size='small' />
      {name}
    </div>
  );
};

type Props = {
  onClose: () => void;
  formData: FormData;
  setFormData: Dispatch<SetStateAction<FormData>>;
  onSubmit: () => void;
  predefined?: boolean;
};

const FormRole = ({ onClose, formData, setFormData, onSubmit, predefined }: Props) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'members.manageRoles.createOrEditRole'
  });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const { t: tCategories } = useTranslation('translation', {
    keyPrefix: 'members.manageRoles.categories'
  });

  const sections = ['carbonFootprint', 'csrd'];
  if (predefined) sections.unshift('generalActions');

  const [loadingButton, setLoadingButton] = useState(false);
  const { onChangeValue } = useOnChangeValue({ setFormData });

  const handleSubmit = async () => {
    setLoadingButton(true);

    const newErrors = checkFormErrors(formData, formData.errors);

    if (formData.permissions.length === 0) {
      newErrors.push({ error: 'categories' });
    }

    if (newErrors.length > 0) {
      setFormData({ ...formData, errors: newErrors });
      setLoadingButton(false);
      return;
    }

    onSubmit();

    setLoadingButton(false);
  };

  const renderTooltip = (category: Category) => {
    return category.tooltip ? (
      <Tooltip
        text={t(category.tooltip)}
        position='right'
        style={{ display: 'flex', alignItems: 'center' }}
      />
    ) : null;
  };

  return (
    <>
      <FormHeader title={t('title')} description={t('description')} />
      <FormWrapper className={predefined ? '' : 'mb-10'}>
        <FormElementHalf>
          <FormText
            iconV2='user'
            disabled={predefined}
            label={t('roleName')}
            placeholder={t('roleNamePlaceholder')}
            onChange={onChangeValue('name')}
            value={formData.name}
            error={formData.errors.find((error) => error.error === 'name')}
          />
          {predefined && (
            <BlueWrapper className='inline-flex w-fit-content my-2 overflow-visible whitespace-nowrap px-2 py-1'>
              <div className='flex items-center gap-1 px-1'>
                <Icon icon='status' color='pending' />
                <span className={'font-12 weight-400 text-secondary-purple-20'}>
                  {t('changesDisabled')}
                </span>
              </div>
            </BlueWrapper>
          )}
        </FormElementHalf>
        <div className='flex flex-col gap-4'>
          <span className='font-12 weight-600'>{t('selectPermissions')}</span>
          {sections.map((section) => {
            const categories: Category[] = useCategories(section);
            return (
              <>
                <Tag name={t(`sections.${section}`)} />
                <div className='flex wrap gap-4'>
                  {categories.map((category) => (
                    <Checkbox
                      disabled={predefined}
                      color={predefined ? 'gray' : 'gradient'}
                      key={category.name}
                      text={
                        <div className='flex items-center'>
                          {tCategories(category.name)}
                          <div className='ml-2'>{renderTooltip(category)}</div>
                        </div>
                      }
                      onChange={(value: boolean) => {
                        if (value) {
                          setFormData({
                            ...formData,
                            permissions: [...formData.permissions, category.name],
                            errors: formData.errors.filter((error) => error.error !== 'categories')
                          });
                        } else {
                          setFormData({
                            ...formData,
                            permissions: formData.permissions.filter((cat) => cat !== category.name)
                          });
                        }
                      }}
                      selected={formData.permissions.includes(category.name)}
                      style={{
                        flexBasis: categories.length === 1 ? '100%' : 'calc(50% - 2rem)',
                        boxSizing: 'border-box',
                        color: formData.errors.find((error) => error.error === 'categories')
                          ? error_color_text
                          : 'inherit',
                        marginTop: '0',
                        marginBottom: '0'
                      }}
                    />
                  ))}
                </div>
              </>
            );
          })}
        </div>
      </FormWrapper>
      {!predefined && (
        <FormButtonSection>
          <Button lookAndFeel='secondary' text={tGeneral('cancel')} onClick={onClose} />
          <Button
            lookAndFeel='primary'
            text={tGeneral('saveAndClose')}
            onClick={handleSubmit}
            loading={loadingButton}
          />
        </FormButtonSection>
      )}
    </>
  );
};

export default FormRole;
