import { getUrl } from 'utils/url';
import apiFetch from '../../utils/apiFetch';

export const checkMembership = async (user_id: string, organization_id: string) => {
  try {
    const response = await apiFetch(
      'GET',
      `/memberships/users/${user_id}/organizations/${organization_id}`
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const deleteMembership = async (user_id: string, organization_id: string) => {
  try {
    const response = await apiFetch(
      'DELETE',
      `/memberships/users/${user_id}/organizations/${organization_id}`
    );
    return response.data;
  } catch (err) {
    return null;
  }
};

export const getMembershipsPaginated = async (urlOptions?: URLOptions) => {
  try {
    const response = await apiFetch('GET', getUrl('/memberships', urlOptions));

    return response.data;
  } catch (err) {
    return null;
  }
};

export const updateFundMembership = async (organization_id: string, role: string) => {
  try {
    const response = await apiFetch('PUT', '/memberships/fund', {
      organization_id,
      role
    });
    return response.data;
  } catch (err) {
    return null;
  }
};
