import apiFetch from 'utils/apiFetch';

export enum RoleLevel {
  OWN = 'own',
  HOLDING = 'holding',
  ALL = 'all'
}

export const getRoles = async (level: RoleLevel, includeGlobalRoles = false) => {
  try {
    const response = await apiFetch('GET', '/v1/roles', null, null, {
      level,
      include_global_roles: includeGlobalRoles,
      page: 1,
      size: 100
    });

    return response.data.items;
  } catch (error) {
    console.error(error);
    return [];
  }
};

type RoleBody = {
  name: string;
  description: string;
  permissions: number[]; // Permission IDs
};
export const createRole = async (role: RoleBody) => {
  try {
    const response = await apiFetch('POST', '/v1/roles', role);

    return response.data;
  } catch (error) {
    console.error(error);
    return null;
  }
};
