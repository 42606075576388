import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import { useTranslation } from 'react-i18next';
import RoleCard from './components/roleCard/RoleCard';
import Icon from 'components/ui/icon/Icon';
import Button from 'components/ui/button/Button';
import useRoles from './hooks/useRoles';
import BlueWrapper from 'components/ui/blueWrapper/BlueWrapper';

type Props = {
  setCreateOrEditRole: (showCreateOrEditRole: { open: boolean; role: Role | null }) => void;
};

const ManageRoles = ({ setCreateOrEditRole }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'members.manageRoles' });
  const { data, isLoading } = useRoles();

  console.log('data', data);

  const roles = data?.roles ?? [];
  const predefinedRoles = data?.predefinedRoles ?? [];

  return (
    <>
      <FormHeader
        title={t('title')}
        description={
          <>
            {t('description')}
            {
              /* TODO: only show for administator user */
              <BlueWrapper className='inline-flex w-fit-content mt-6 my-2 py-1 px-2'>
                <div className='flex items-center gap-1'>
                  <Icon icon='status' color='pending' />
                  <span className={'font-12 text-secondary-purple-20 weight-400'}>
                    {t('administratorMessage')}
                  </span>
                </div>
              </BlueWrapper>
            }
          </>
        }
      />
      <div className='flex gap-2 rounded-8 wrap' style={{ maxHeight: '400px', overflowY: 'auto' }}>
        <div
          className='rounded-8 app-bg-color flex items-center justify-center p-4'
          style={{ flexBasis: 'calc(50% - 0.5rem)', boxSizing: 'border-box' }}>
          <Button
            lookAndFeel='primary'
            text={t('createNewRole')}
            iconNode={<Icon icon='add' color='white' />}
            size='small'
            style={{ width: 'fit-content' }}
            onClick={() => setCreateOrEditRole({ open: true, role: null })}
          />
        </div>
        {roles?.map((role: Role) => (
          <RoleCard
            role={role}
            key={role.name}
            onClick={() => setCreateOrEditRole({ open: true, role: role })}
          />
        ))}
        {predefinedRoles?.map((role: Role) => (
          <RoleCard
            role={role}
            key={role.name}
            onClick={() => setCreateOrEditRole({ open: true, role: role })}
          />
        ))}
      </div>
    </>
  );
};

export default ManageRoles;
