import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import FormWrapper from 'components/ui/formComponents/formWrapper/FormWrapper';
import FormSelect from 'components/ui/formComponents2/formInputs/formSelect/FormSelect';
import useOnChangeValue from 'customHooks/useOnChangeValue';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useOptions from './hooks/useOptions';
import CustomSkeletonLoader from 'components/ui/loaders/customSkeletonLoader/CustomSkeletonLoader';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import Button from 'components/ui/button/Button';
import { User } from 'types/entities/user';
import checkFormErrors from 'utils/checkFormErrors';

type FormData = {
  role: SelectOptionFormat;
  errors: ErrorType[];
};
type Props = {
  memberToEdit: User;
  onClose: () => void;
  editMemberCallback: (member: User) => void;
};
const EditMember = ({ memberToEdit, onClose, editMemberCallback }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'members' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const [formData, setFormData] = useState<FormData>({
    role: {
      id: memberToEdit.role ?? '',
      name: t(memberToEdit.role ?? '')
    },
    errors: []
  });
  const [loadingButton, setLoadingButton] = useState(false);

  const { onChangeValue } = useOnChangeValue({ setFormData });

  const { data: options, isLoading } = useOptions();

  const handleEditMember = async () => {
    const newErrors = checkFormErrors(formData, formData.errors, []);
    if (newErrors.length > 0) {
      setFormData({ ...formData, errors: newErrors });
      return;
    }
    setLoadingButton(true);

    // TODO: call api to patch new role when it's ready

    alert('PATO WE NEED U');

    setLoadingButton(false);

    editMemberCallback({
      ...memberToEdit,
      role: formData.role.id
    });
  };
  return (
    <>
      <FormHeader title={t('editMember')} />
      <FormWrapper className='mb-10'>
        {isLoading ? (
          <CustomSkeletonLoader count={1} />
        ) : (
          <FormSelect
            icon={'/images/icons/key.svg'}
            placeholder={t('selectRole')}
            label={t('role')}
            value={formData.role}
            onChange={onChangeValue('role')}
            options={options ?? []}
            error={formData.errors.find((elem) => elem.error === 'role')}
          />
        )}
      </FormWrapper>
      <FormButtonSection>
        <Button lookAndFeel='secondary' text={tGeneral('cancel')} onClick={onClose} />
        <Button
          lookAndFeel='primary'
          disabled={formData.role.name.toLowerCase() === memberToEdit?.role?.toLowerCase()} // TODO: check by ID's instead of names
          text={t('save')}
          onClick={handleEditMember}
          loading={loadingButton}
        />
      </FormButtonSection>
    </>
  );
};

export default EditMember;
