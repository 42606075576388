import { useTranslation } from 'react-i18next';
import Label from '../../label/Label';
import Line from '../line/Line';
import './styles.scss';
import { ReactNode } from 'react';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import Icon from 'components/ui/icon/Icon';

export interface Props extends React.HTMLAttributes<HTMLDivElement> {
  text: ReactNode;
  isSelected: boolean;
  lookAndFeel?: 'big' | 'small' | 'breadcrumb';
  error?: string;
  disabled?: boolean;
  blocked?: boolean;
}

const useCarouselTranslation = () => {
  const { t: translate, i18n } = useTranslation();

  const t = (text: ReactNode) => {
    if (typeof text === 'string' && i18n.exists(text)) {
      return translate(text);
    }

    return text;
  };

  return { t };
};

const StepCarousel = ({
  text,
  isSelected,
  lookAndFeel = 'big',
  error,
  disabled,
  blocked,
  ...rest
}: Props) => {
  const { t } = useCarouselTranslation();

  const className = ['step-carousel'];

  if (lookAndFeel === 'big') {
    className.push('big');
  }

  if (lookAndFeel === 'breadcrumb') {
    className.push('body1-font');

    if (isSelected) {
      className.push('on-light-text-color');
    }

    if (!isSelected) {
      className.push('on-light-text-color2');
    }
  }

  if (disabled) {
    className.push('text-neutral-gray-20');
  }

  const textComponentBig = blocked ? (
    <TooltipWrapper asChild text={t('roles.blocked')}>
      <span className='flex items-center gap-x-1 disabled-text-color'>
        {t(text)}
        <Icon icon='lock' color='disabled' size='small' />
      </span>
    </TooltipWrapper>
  ) : (
    <>{t(text)}</>
  );

  if (lookAndFeel === 'big') {
    return (
      <div className={className.join(' ')} {...rest}>
        <span className={`${error ? 'error-text-color' : ''} body3-font`}>{textComponentBig}</span>
        <Line isSelected={isSelected} isBlocked={blocked}></Line>
      </div>
    );
  }

  if (lookAndFeel === 'small') {
    return (
      <div className={className.join(' ')} {...rest}>
        <Label lookAndFeel={isSelected ? 'primary' : 'secondary'}>{t(text)}</Label>
      </div>
    );
  }

  if (lookAndFeel === 'breadcrumb') {
    return (
      <div className={className.join(' ')} {...rest}>
        {t(text)}
        {!isSelected && ' > '}
      </div>
    );
  }

  return null;
};

export default StepCarousel;
