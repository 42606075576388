import Button from 'components/ui/button/Button';
import ErrorText from 'components/ui/errorText/ErrorText';
import FormButtonSection from 'components/ui/formComponents/formButtonSection/FormButtonSection';
import FormHeader from 'components/ui/formComponents/formHeader/FormHeader';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  deleteMemberCallback: (id: string) => void;
  memberToDelete: string;
};
const DeleteMember = ({ deleteMemberCallback, memberToDelete }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'members' });
  const [loadingButton, setLoadingButton] = useState(false);

  const [error, setError] = useState<ErrorType>();

  const handleDeleteMember = async () => {
    setLoadingButton(true);
    // TODO: call api to delete member when it's ready
    // TODO: map error if happens to state variable
    deleteMemberCallback(memberToDelete);
    setLoadingButton(false);
  };
  return (
    <>
      <FormHeader title={t('deleteMember')} description={t('areYouSureDelete')} />
      <FormButtonSection>
        <Button
          lookAndFeel='warning'
          text={t('delete')}
          onClick={handleDeleteMember}
          loading={loadingButton}
        />
      </FormButtonSection>
      {error && <ErrorText>{error.description}</ErrorText>}
    </>
  );
};

export default DeleteMember;
