export type Category = {
  name: string;
  tooltip?: string;
};

const useCategories = (mainCategory: string) => {
  const categories: {
    [key: string]: Category[];
  } = {
    generalActions: [
      { name: 'usersAndRolesManagement' },
      { name: 'linkedOrganizationsManagement' },
      { name: 'taskManagement' },
      { name: 'fiscalInformationManagement' },
      { name: 'submittedFilesDeletion' },
      { name: 'dashboardConsult' },
      { name: 'improveAndCommunicateSectionsAccess' },
      { name: 'divideInvoicesBetweenOrganizations' },
      { name: 'createCustomEFs' },
      { name: 'createIntensityMetricsAndGroupings' }
    ],
    carbonFootprint: [
      { name: 'heat' },
      { name: 'recharge' },
      { name: 'electricity' },
      { name: 'water' },
      { name: 'waste' },
      { name: 'vehicles' },
      { name: 'employees' },
      { name: 'business_travel' },
      { name: 'upstream' },
      { name: 'downstream' },
      { name: 'purchases' }
    ],
    csrd: [{ name: 'sensitiveInformation', tooltip: 'csrdTooltip' }]
  };

  return categories[mainCategory] as Category[];
};

export default useCategories;
