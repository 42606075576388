import './styles.scss';

type Props = {
  isSelected: boolean;
  isBlocked?: boolean;
};

const Line = ({ isSelected, isBlocked }: Props) => {
  let backgroundColor = 'secondary-bg-color';
  if (isBlocked || !isSelected) {
    backgroundColor = 'gray-bg-color2';
  }
  return <div className={`carousel-line ${backgroundColor}`}></div>;
};

export default Line;
