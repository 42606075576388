import Icon from 'components/ui/icon/Icon';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TooltipWrapper from 'components/ui/tooltip/TooltipWrapper';
import Category from './Category';

type Props = {
  role: Role;
  onClick: () => void;
};
const RoleCard = ({ role, onClick }: Props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'members.manageRoles.categories' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const [maxCategories, setMaxCategories] = useState(0);
  const [widthCategories, setWidthCategories] = useState(0);

  const MAX_WIDTH_CATEGORIES = 160; // in px

  const buildTooltipMessage = (categories: string[]) => {
    return (
      t('tooltip') +
      ' ' +
      categories
        .map((category, index) => {
          if (index === categories.length - 1) return tGeneral('and') + ' ' + t(category);
          return t(category);
        })
        .join(', ') +
      '.'
    );
  };

  const addWidthCallback = (width: number) => {
    // Add up widths of categories until it reaches
    setWidthCategories((prevWidth) => {
      const newWidth = prevWidth + width;
      if (newWidth < MAX_WIDTH_CATEGORIES) {
        setMaxCategories((prevMax) => prevMax + 1);
        return newWidth;
      }
      return prevWidth;
    });
  };

  const predefined = false;

  const visibleCategories =
    // role.fixedTags ??
    role.permissions
      .sort((a, b) => {
        // sort by the number of chars in the category name, first the ones with less chars
        return t(a.type).length - t(b.type).length;
      })
      .slice(0, maxCategories);
  const rest = role.permissions.length - visibleCategories.length;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className='flex rounded-8 py-6 px-4 solid-border hover:border-primary items-center gap-4 pointer'
      style={{
        flexBasis: 'calc(50% - 0.5rem)',
        boxSizing: 'border-box',
        maxWidth: 'calc(50% - 0.5rem)'
      }}
      onClick={onClick}>
      <div
        className={`flex items-center justify-center rounded-8 w-8 h-8 ${
          predefined ? 'bg-purple-40' : 'bg-purple'
        }`}>
        <Icon icon='user' color='white' size='large' />
      </div>
      <div className='flex flex-col gap-2'>
        <span className='font-14 weight-600'>{role.name}</span>
        <div className='flex gap-1 items-center'>
          {visibleCategories.map((permission: Permission) => (
            <Category
              name={t(permission.type)}
              key={permission.type}
              addWidthCallback={addWidthCallback}
            />
          ))}
          {rest > 0 && (
            <TooltipWrapper
              key={'rest'}
              text={buildTooltipMessage(role.permissions.map((permission) => permission.type))}
              position='right'>
              <Category name={`+${rest}`} addWidthCallback={addWidthCallback} />
            </TooltipWrapper>
          )}
        </div>
      </div>
    </div>
  );
};

export default RoleCard;
