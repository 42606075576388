import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { SIZE_PAGINATION_BIG } from '../../../constants';
import { getMembershipsPaginated } from 'services/api/membership';
import { generateQueryParamsFromObject } from 'utils/url';

type Params = {
  filters?: IFilter[];
  sorters?: ISorter[];
};
const useGetData = ({ filters, sorters }: Params) => {
  const queryClient = useQueryClient();

  const makeFetch = async ({
    filters,
    sorters,
    page,
    size
  }: Params & { page: number; size: number }) => {
    const queryParams = generateQueryParamsFromObject({ page, size });

    const response = await getMembershipsPaginated({ queryParams, filters, sorters });

    return response;
  };
  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ['memberships'] });
  };

  const { data, refetch, fetchNextPage, isFetching, isLoading } = useInfiniteQuery({
    queryKey: ['memberships', JSON.stringify(filters), JSON.stringify(sorters)],
    queryFn: ({ pageParam }) =>
      makeFetch({ filters, sorters, page: pageParam, size: SIZE_PAGINATION_BIG }),
    getNextPageParam: (lastPage) => {
      if (lastPage.page * lastPage.size >= lastPage.total) {
        return undefined;
      }

      return lastPage.page + 1;
    },
    initialPageParam: 1,
    select: (data) => {
      return data.pages.reduce(
        (acc, current) => {
          return {
            total: current.total,
            total2: current.total2,
            page: current.page,
            size: current.size,
            items: [...acc.items, ...current.items].map((item) => ({
              ...item,
              disabled: !item.first_name // It's an invitation
            }))
          };
        },
        { total: 0, total2: 0, page: 0, size: 0, items: [] }
      );
    }
  });
  return {
    data: data?.items ?? [],
    total: data?.total ?? 0,
    total2: data?.total2 ?? 0,
    fetchData: fetchNextPage,
    loading: isFetching,
    firstLoading: isLoading,
    refreshData: refetch,
    invalidate
  };
};

export default useGetData;
