import React, { useState } from 'react';
import FormRole from './FormRole';
import { createRole } from 'services/api/roles';

type Props = {
  role: Role | null;
  onClose: () => void;
};

export type FormData = {
  name: string;
  permissions: string[];
  errors: ErrorType[];
};

const CreateOrEditRole = ({ role, onClose }: Props) => {
  const [formData, setFormData] = useState<FormData>(
    role
      ? {
          name: role.name,
          permissions: role.permissions.map((permission) => permission.type),
          errors: []
        }
      : {
          name: '',
          permissions: [],
          errors: []
        }
  );

  const onSubmit = async () => {
    if (role) {
      alert('API call to EDIT role.');
    } else {
      createRole({
        name: formData.name,
        description: '',
        permissions: [] // UNTIL WE LIST THE CORRECT PERMISSIONS
      });
    }
  };

  const predefined = false;

  return (
    <FormRole
      onClose={onClose}
      formData={formData}
      setFormData={setFormData}
      onSubmit={onSubmit}
      predefined={predefined}
    />
  );
};

export default CreateOrEditRole;
