import {
  ReactFlow,
  Background,
  BackgroundVariant,
  Controls,
  Edge,
  useReactFlow
} from '@xyflow/react';

import '@xyflow/react/dist/style.css';

import { useCallback, useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { withReactFlow } from 'lib/ReactFlow/withReactFlow';
import { useACVId } from '../../hooks/useACVId';
import { useCreateLCAConnection } from '../../hooks/useCreateLCAConnection';
import { useCreateNode } from '../../hooks/useCreateNode';
import { useDeleteLCAConnection } from '../../hooks/useDeleteLCAConnection';
import { useLCABlocks } from '../../hooks/useLCABlocks';
import { useLCAEdges } from '../../hooks/useLCAEdges';
import { useLCANodes } from '../../hooks/useLCANodes';
import { useMoveNodes } from '../../hooks/useMoveNodes';
import { EDGE_TYPES, NODE_COMPONENTS } from '../../reactFlowConfig';
import { nodeToSubmit } from '../../schema/nodeToSubmit';
import { ModalHandler } from '../ModalHandler';
import { ModalData } from '../../types/LCAScenarios';
import { FitViewBTN } from '../../../../ui/canvas/CustomControls/FitViewBtn';
import { AddBlock } from '../CustomControls/AddBlock';
import { CustomConnector } from '../CustomConnector/CustomConnector';
import { getDeleteKeyCode } from 'utils/canvas/getDeleteKeyCode';
import { isDeletableEdge } from '../../utils/isDeletableEdge';
import { isValidConnection } from '../../utils/isValidConnection';
import { DIAGRAM_CONFIG } from './constants';

export const LCADiagram = withReactFlow(() => {
  const { acv_id } = useACVId();
  const { createNode } = useCreateNode({ acv_id });
  const { moveNodes } = useMoveNodes({ acv_id });
  const { screenToFlowPosition } = useReactFlow();
  const { lcaBlocks, isLoading: loadingLCABlocks } = useLCABlocks({ acv_id });

  const { deleteConnection } = useDeleteLCAConnection();
  const { createConnection } = useCreateLCAConnection();

  const [edges, _setEdges, onEdgesChange] = useLCAEdges({
    lcaBlocks: lcaBlocks ?? [],
    isLoading: loadingLCABlocks
  });

  const [nodes, _setNodes, onNodesChange, nodesMap] = useLCANodes({
    lcaBlocks: lcaBlocks ?? [],
    isLoading: loadingLCABlocks
  });

  const [isDeletable, setIsDeletable] = useState(false);
  const [objectToSubmit, setObjectToSubmit] = useState<
    | {
        type?: 'process' | 'material';
        target?: string;
        coordinate_x?: number;
        coordinate_y?: number;
      }
    | undefined
  >();
  const [modalData, setModalData] = useState<ModalData | undefined>();
  const [highlightedEdges, setHighlightedEdges] = useState<string[]>([]);

  const [_collected, drop] = useDrop(
    () => ({
      accept: 'lca-node',
      drop: (item: { type: 'process' | 'material' }) => {
        setObjectToSubmit((prev) => ({
          ...prev,
          type: item.type,
          target: modalData?.target
        }));
      }
    }),
    [modalData?.target]
  );

  const handleDeleteConnection = useCallback(async (edges: Edge[]) => {
    await Promise.all(
      edges.map((edge) => {
        deleteConnection({ acv_id, source_id: edge.source, target_id: edge.target });
      })
    );
  }, []);

  useEffect(() => {
    const validation = nodeToSubmit.safeParse(objectToSubmit);

    if (validation.success) {
      createNode({
        ...validation.data,
        acv_id
      });

      setObjectToSubmit(undefined);
    }
  }, [JSON.stringify(objectToSubmit)]);

  const final_product = lcaBlocks?.find(
    (block) => block.type === 'product' && block.outputs.length === 0
  )?.name;

  return (
    <ModalHandler.Root modalData={modalData} setModalData={setModalData}>
      <ModalHandler.Main final_product={final_product ?? ''} />

      <div className='flex-grow relative border-box on-card-gray-bg-color card-border-color border-1 border-solid rounded-8'>
        <ReactFlow
          nodeOrigin={DIAGRAM_CONFIG.NODE_ORIGIN}
          minZoom={DIAGRAM_CONFIG.MIN_ZOOM}
          nodes={nodes}
          nodeTypes={NODE_COMPONENTS}
          edges={edges}
          edgeTypes={EDGE_TYPES}
          proOptions={DIAGRAM_CONFIG.PRO_OPTIONS}
          fitView
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onEdgesDelete={handleDeleteConnection}
          connectionLineComponent={CustomConnector}
          nodeDragThreshold={DIAGRAM_CONFIG.NODE_DRAG_THRESHOLD}
          onNodeDragStop={async (_event, node) => {
            const nodes_to_move = nodes.filter((n) => n.selected || n.id === node.id);
            await moveNodes({
              acv_id,
              blocks: nodes_to_move.map((n) => ({
                id: n.id,
                coordinates_x: n.position.x,
                coordinates_y: n.position.y
              }))
            });
          }}
          onDrop={(event) => {
            const position = screenToFlowPosition({
              x: event.clientX,
              y: event.clientY
            });

            setObjectToSubmit((prev) => ({
              ...prev,
              coordinate_x: position.x,
              coordinate_y: position.y
            }));
          }}
          elevateEdgesOnSelect
          onSelectionChange={({ nodes, edges }) => {
            setIsDeletable(isDeletableEdge(nodes, edges, nodesMap));
          }}
          onConnectEnd={(_, { toNode, fromNode }) => {
            if (!toNode || !fromNode) return;

            const sourceNodes = nodes.filter(isValidConnection({ toNode, fromNode }));

            if (sourceNodes.length === 0) return;

            createConnection({
              acv_id,
              source_ids: sourceNodes.map((node) => node.id),
              target_id: toNode.id
            });
          }}
          deleteKeyCode={getDeleteKeyCode(isDeletable)}
          ref={drop}>
          <Controls
            showFitView={false}
            showInteractive={false}
            showZoom={false}
            className='bg-neutral-gray-warm-60 p-2 rounded-8 flex gap-x-4'
            orientation='horizontal'>
            <AddBlock />
            <FitViewBTN />
          </Controls>
          <Background variant={BackgroundVariant.Lines} gap={48} />
        </ReactFlow>
      </div>
    </ModalHandler.Root>
  );
});
