import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getRoles, RoleLevel } from 'services/api/roles';

const ALL_CATEGORIES = [
  'heat',
  'recharge',
  'electricity',
  'water',
  'waste',
  'vehicles',
  'employees',
  'business_travel',
  'upstream',
  'downstream',
  'purchases',
  'sensitiveInformation',
  'usersAndRolesManagement',
  'socialAndGovernance',
  'dashboard',
  'linkedOrganizationsManagement',
  'taskManagement',
  'fiscalInformationManagement',
  'submittedFilesDeletion',
  'dashboardConsult',
  'improveAndCommunicateSectionsAccess',
  'divideInvoicesBetweenOrganizations',
  'createCustomEFs',
  'createIntensityMetricsAndGroupings'
];

const useRoles = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'members.manageRoles.predefinedRoles' });
  return useQuery({
    queryKey: ['roles'],
    queryFn: async () => {
      const response = await getRoles(RoleLevel.ALL, true);
      return response;
    },
    select: (data) => {
      return {
        roleOptions: data.map((role: Role) => ({ id: role.id, name: role.name })),
        roles: data.map((role: Role) => ({
          ...role,
          permissions: [
            {
              id: '1',
              category: 'carbon_footprint',
              resource: 'emission:heat',
              type: 'create',
              description: 'Lorem ipsum'
            }
          ]
        })),
        predefinedRoles: []
      };
    }
  });
  // const mockedRoles: Role[] = [
  //   {
  //     name: 'Mantenimiento',
  //     categories: ['heat', 'recharge', 'waste', 'water', 'upstream', 'downstream'],
  //     dateCreated: '2021-06-01'
  //   },
  //   {
  //     name: 'Operador',
  //     categories: [
  //       'heat',
  //       'recharge',
  //       'upstream',
  //       'business_travel',
  //       'purchases',
  //       'sensitiveInformation'
  //     ],
  //     dateCreated: '2021-03-01'
  //   },
  //   {
  //     name: 'Supervisor',
  //     categories: ['heat', 'recharge'],
  //     dateCreated: '2023-06-01'
  //   },
  //   {
  //     name: 'Marketing',
  //     categories: ['heat', 'recharge'],
  //     dateCreated: '2024-06-01'
  //   }
  // ];

  // const predefinedRoles: Role[] = [
  //   {
  //     name: t('administrator'),
  //     categories: ALL_CATEGORIES,
  //     fixedTags: ['completeEdition', 'usersAndRolesManagement']
  //   },
  //   {
  //     name: t('member'),
  //     categories: ALL_CATEGORIES.filter(
  //       (c) =>
  //         ![
  //           'fiscalInformationManagement',
  //           'linkedOrganizationsManagement',
  //           'usersAndRoleManagement',
  //           'divideInvoicesBetweenOrganizations',
  //           'uploadedFilesDeletion'
  //         ].includes(c)
  //     ),
  //     fixedTags: ['limitedEdition']
  //   },
  //   {
  //     name: t('holdings'),
  //     categories: ALL_CATEGORIES.filter((c) => !['fiscalInformationManagement'].includes(c)),
  //     fixedTags: ['completeEdition', 'usersAndRolesManagement']
  //   },
  //   {
  //     name: t('funds'),
  //     categories: ['dashboardConsult', 'socialAndGovernance'],
  //     fixedTags: ['dashboard', 'socialAndGovernance']
  //   }
  // ];

  // const roleOptions: SelectOptionFormat[] = mockedRoles.map((role) => ({
  //   id: role.name,
  //   name: role.name
  // }));

  // return { predefinedRoles, roles: mockedRoles, roleOptions };
};

export default useRoles;
