import Breadcrumb from 'components/layout/breadcrumb/Breadcrumb';
import MainPageLayout from 'components/layout/mainPageLayout/MainPageLayout';
import Button from 'components/ui/button/Button';
import Icon from 'components/ui/icon/Icon';
import InfiniteList from 'components/ui/infiniteList/InfiniteListV2';
import Modal from 'components/ui/modal/Modal';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useColumns from './hooks/useColumns';
import useGetData from './hooks/useGetData';
import TotalLegend from 'components/ui/totalLegend/TotalLegend';
import EditMember from './editMember/EditMember';
import { User } from 'types/entities/user';
import DeleteMember from './deleteMember/DeleteMember';
import ManageRoles from './manageRoles/ManageRoles';
import AddMemberV2 from './addMember/AddMemberV2';
import CreateOrEditRole from './manageRoles/components/createOrEditRole/CreateOrEditRole';
import { Filters } from 'components/layout/NewFilters/Filters';
import FiltersDate from 'components/layout/NewFilters/FiltersDate';
import { FilterSection } from 'components/layout/NewFilters/FilterSection';
import { FilterText } from 'components/layout/NewFilters/FilterText';
import Sorting from 'components/ui/sorting/Sorting';
import FilterHandlers from 'components/layout/NewFilters/FiltersHandlers';
import { InputSize } from 'types/utilsEnums/input';
import useSortingOptions from './hooks/useSortingOptions';

const MembersV2 = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'members' });
  const { t: tInput } = useTranslation('translation', { keyPrefix: 'input' });
  const [showAddNewUser, setShowAddNewUser] = useState(false);
  const [showManageRoles, setShowManageRoles] = useState(false);
  const [showCreateOrEditRole, setCreateOrEditRole] = useState<{
    open: boolean;
    role: Role | null;
  }>({
    open: false,
    role: null
  });
  const [filters, setFilters] = useState<IFilter[]>([]);
  const [sorters, setSorters] = useState<ISorter[]>([
    {
      field: 'created_at',
      order: 'desc'
    }
  ]);
  const [open, setOpen] = useState(false);

  const { columns, memberToEdit, memberToDelete, setMemberToEdit, setMemberToDelete } =
    useColumns();

  const sortingOptions = useSortingOptions();
  const { data, fetchData, loading, firstLoading, total } = useGetData({
    filters,
    sorters
  });

  const onSort = (value: SelectOptionFormat, sortDirection?: 'asc' | 'desc') => {
    if (!value) return;
    const sorters = [
      {
        field: value.id,
        order: sortDirection || 'asc'
      }
    ];
    setSorters(sorters);
  };

  return (
    <MainPageLayout
      sectionTitle={t('title')}
      title={t('subtitle')}
      gridTemplateRows='4.5rem 0.05fr 10rem 4rem 1fr'
      breadcrumb={<Breadcrumb />}
      buttons={
        <div className='flex gap-1 items-center'>
          <Button
            lookAndFeel='primary'
            text={t('addNewUser')}
            iconNode={<Icon icon='add' color='white' />}
            onClick={() => setShowAddNewUser(true)}
            size='small'
          />
          <Button
            lookAndFeel='secondary'
            text={t('manageRolesButton')}
            iconNode={<Icon icon='settings' color='gray-dark' />}
            onClick={() => setShowManageRoles(true)}
            size='small'
          />
        </div>
      }>
      <Filters.Root setFilters={setFilters} filters={filters} setOpen={setOpen} open={open}>
        <Filters.Menu>
          <FiltersDate fields={['created_at']} />
          {/* <FilterSection.Multiple
            title={t('purchases.status')}
            field='status'
            type='in'
            options={Object.values(SHIPMENT_STATUS).map((status) => ({
              value: status,
              label: statusIconSelect(status, [], false)
            }))}
          /> */}
        </Filters.Menu>
        <InfiniteList
          data={data}
          fetchData={fetchData}
          columns={columns}
          loading={loading}
          firstLoading={firstLoading}
          total={total}
          header={
            <>
              <div className='flex gap-x-2 items-center'>
                <FilterText
                  field='email'
                  type='il'
                  placeholder={t('email')}
                  size={InputSize.MEDIUM}
                />
                <Sorting
                  options={sortingOptions}
                  label={tInput('sorting.orderBy')}
                  placeholder={tInput('placeholderSelect')}
                  onSort={onSort}
                  size={InputSize.MEDIUM}
                />
                <FilterHandlers blacklistedFilters={{ all: ['email'] }} />
              </div>
              <TotalLegend total={total} loading={loading} i18key='members' />
            </>
          }
        />
      </Filters.Root>
      <Modal.WithPortal
        show={showAddNewUser}
        onClose={() => setShowAddNewUser(false)}
        width='657px'
        maxWidth='657px'>
        <AddMemberV2 onClose={() => setShowAddNewUser(false)} members={data as User[]} />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={showManageRoles && !showCreateOrEditRole.open}
        onClose={() => setShowManageRoles(false)}
        width='900px'
        maxWidth='900px'>
        <ManageRoles setCreateOrEditRole={setCreateOrEditRole} />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={!!memberToEdit}
        onClose={() => setMemberToEdit(undefined)}
        width='570px'
        maxWidth='570px'>
        {memberToEdit && (
          <EditMember
            memberToEdit={memberToEdit}
            onClose={() => setMemberToEdit(undefined)}
            editMemberCallback={(member: User) => {
              setMemberToEdit(undefined);
              // TODO: update data instead of reloading the page
              window.location.reload();
            }}
          />
        )}
      </Modal.WithPortal>

      <Modal.WithPortal
        show={!!memberToDelete}
        onClose={() => setMemberToDelete('')}
        width='428px'
        maxWidth='428px'>
        <DeleteMember
          deleteMemberCallback={(id: string) => {
            setMemberToDelete('');
            window.location.reload();
          }}
          memberToDelete={memberToDelete}
        />
      </Modal.WithPortal>
      <Modal.WithPortal
        show={showCreateOrEditRole.open}
        onClose={() => setCreateOrEditRole({ open: false, role: null })}
        width='660px'
        maxWidth='660px'>
        <CreateOrEditRole
          onClose={() => setCreateOrEditRole({ open: false, role: null })}
          role={showCreateOrEditRole.role}
        />
      </Modal.WithPortal>
    </MainPageLayout>
  );
};

export default MembersV2;
