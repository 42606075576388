import { useQuery } from '@tanstack/react-query';
import { getRoles, RoleLevel } from 'services/api/roles';

const useOptions = () => {
  return useQuery({
    queryKey: ['roles'],
    queryFn: async () => {
      const response = await getRoles(RoleLevel.ALL);
      return response;
    },
    select: (data) => {
      return data.map((role: Role) => ({ id: role.id, name: role.name }));
    }
  });
};

export default useOptions;
